<template>
  <form
    v-click-outside="closeListbox"
    class="border-action-default-low rounded-sm border"
    :class="{ relative: !fullscreen }"
    role="search"
    @submit.prevent="submit"
  >
    <Textbox
      :input-id="inputId"
      :input-name="SCHEMA_SEARCH_NAME"
      :is-focus="shouldFocusInput"
      :isListboxOpen
      :listboxResultsMessage
      :value="inputValue"
      @change="changeHandler"
      @close-listbox="closeListbox"
      @focus="openListbox"
      @keydown="changeSelectedIndex"
      @submit="submit"
    />
    <div
      v-show="isListboxOpen"
      class="bg-overlap-default-low border-static-default-low text-action-default-hi absolute inset-x-0 divide-y overflow-auto border-t"
      :class="{
        'shadow-long rounded-sm mt-3 max-h-448': !fullscreen,
        'top-48 h-[calc(100vh-48px)] translate-x-full': fullscreen,
      }"
    >
      <template v-if="!inputValue">
        <Listbox
          v-if="popularSearches.length > 0"
          :items="popularSearches"
          name="popularSearches"
          :selected-index="selectedIndex"
          :title="i18n(translations.popularSearches)"
          @select="selectIndex"
          @update-input="updateInput"
        />
      </template>

      <template v-if="inputValue">
        <Listbox
          v-if="suggestions"
          :input-value="inputValue"
          :items="suggestions"
          name="suggestions"
          :selected-index="selectedIndex"
          :title="i18n(translations.suggestedSearches)"
          @select="selectIndex"
          @update-input="updateInput"
        />
      </template>
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { clickOutside as vClickOutside } from '@backmarket/utils/directives/ClickOutside'

import type { Link } from '../../composables/useSearch'

import translations from './Combobox.translations'
import Listbox from './Listbox.vue'
import textboxTranslations from './Textbox.translations'
import Textbox from './Textbox.vue'

export type Item = Link & {
  source: string
}

const SCHEMA_SEARCH_NAME = 'q'

const props = withDefaults(
  defineProps<{
    shouldFocusInput?: boolean
    popularSearches?: Link[]
    suggestions?: Link[]
    inputId: string
    fullscreen?: boolean
  }>(),
  {
    shouldFocusInput: false,
    popularSearches: () => [],
    suggestions: () => [],
    fullscreen: false,
  },
)

const i18n = useI18n()

const selectedIndex = ref(-1)
const isListboxOpen = ref(props.fullscreen)
const inputValue = ref('')

const emit = defineEmits(['change', 'submit'])

const addSource = (items: Link[], source: string): Item[] =>
  items.map((item) => ({ ...item, source }))

const items = computed(() => {
  const suggestions = addSource(props.suggestions, 'suggestions')
  const popularSearches = addSource(props.popularSearches, 'popularSearches')

  return inputValue.value ? suggestions : popularSearches
})

const listboxResultsMessage = computed(() => {
  const resultsCount = items.value.length
  const searchQuery = inputValue.value
  const messageWhenQuery = i18n(translations.resultsListing, {
    resultsCount,
    searchQuery,
  })

  return inputValue.value
    ? messageWhenQuery
    : i18n(textboxTranslations.textboxPlaceholder)
})

function changeHandler(value: string) {
  inputValue.value = value
  selectedIndex.value = -1
  emit('change', inputValue.value)
}

function submit() {
  const focusedElement = document.activeElement as HTMLElement
  focusedElement?.blur()

  emit('submit', {
    query: inputValue.value,
    item: items.value?.[selectedIndex.value],
    index: selectedIndex.value,
  })
}

function selectIndex(index?: number) {
  if (index) selectedIndex.value = index
  submit()
}

function updateInput(value: string) {
  changeHandler(value)
}

function changeSelectedIndex(offset: number) {
  const { length } = items.value
  if (length !== 0) {
    selectedIndex.value = (length + selectedIndex.value + offset) % length
  }
}

function closeListbox() {
  if (!props.fullscreen) {
    isListboxOpen.value = false
  }
}

function openListbox() {
  if (!props.fullscreen) {
    selectedIndex.value = -1
    isListboxOpen.value = true
  }
}

onBeforeMount(() => {
  const input = document.querySelector(
    `#${props.inputId}`,
  ) as HTMLInputElement | null

  if (input && input.value) {
    changeHandler(input.value)
  }
})
</script>
